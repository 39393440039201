export const Test = `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M20 20C20 20.6667 19.6 22 18 22C16.4 22 9.33333 22 6 22C5.33333 22 4 21.6 4 20C4 18.4 4 8.66667 4 4C4 3.33333 4.4 2 6 2C7.6 2 14.6667 2 18 2C18.6667 2 20 2.4 20 4C20 5.6 20 10 20 12" />
<path d="M8 16H9L11 12.5L13 16L15 14L17 16H21" />
</svg>`


export const Pdf = `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M18 22C19.6 22 20 20.6667 20 20V12V4C20 2.4 18.6667 2 18 2H6C4.4 2 4 3.33333 4 4V20C4 21.6 5.33333 22 6 22H18Z"/>
<path d="M10.2641 15.4534C10.1799 16.2316 9.92593 17.0794 8.99458 17.7388C8.03608 18.4174 7.80927 17.6446 8.14791 16.891C8.93154 15.581 9.83077 15.4213 10.2641 15.4534ZM10.2641 15.4534L11.9026 11.3306M10.2641 15.4534C10.7925 15.1773 12.3087 14.3904 13.99 14.1592M11.9026 11.3306C11.8789 11.7224 12.2945 12.6951 13.99 14.1592M11.9026 11.3306C12.1342 10.9325 12.7074 9.80713 12.5507 8.78991C12.3548 7.51839 11.6214 8.03177 11.3654 8.60151C11.1114 9.16671 11.2793 11.0802 11.9026 11.3306ZM13.99 14.1592C14.3877 13.9409 15.4631 13.553 16.3607 13.8766C17.4828 14.2812 16.9533 15.3001 16.1067 15.1954C14.5827 15.007 14.752 14.8186 13.99 14.1592Z" />
</svg>
`

export const Truck = `
<svg  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M16 11H23"/>
<path d="M1 16V5C1 4.44772 1.44772 4 2 4H15C15.5523 4 16 4.44772 16 5V15C16 15.5523 15.5523 16 15 16H11"/>
<path d="M3.5 19C1.5 19 1 17.3333 1 16.5C1 15.6667 1.5 14 3.5 14C5.5 14 6 15.6667 6 16.5C6 17.3333 5.5 19 3.5 19Z" />
<path d="M8.5 19C6.5 19 6 17.3333 6 16.5C6 15.6667 6.5 14 8.5 14C10.5 14 11 15.6667 11 16.5C11 17.3333 10.5 19 8.5 19Z"/>
<path d="M19.5 19C17.5 19 17 17.3333 17 16.5C17 15.6667 17.5 14 19.5 14C21.5 14 22 15.6667 22 16.5C22 17.3333 21.5 19 19.5 19Z"/>
<path d="M16 7H19.3468C19.6453 7 19.9282 7.13337 20.1182 7.36366L22.7714 10.58C22.9192 10.7592 23 10.9842 23 11.2164V15C23 15.5523 22.5523 16 22 16H21.7273"/>
</svg>
`

export const Trailer = `
<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M6.5 16.5C4.1 16.5 3.5 14.5 3.5 13.5C3.5 12.5 4.1 10.5 6.5 10.5C8.9 10.5 9.5 12.5 9.5 13.5C9.5 14.5 8.9 16.5 6.5 16.5Z"/>
<path d="M3.34043 14H2C1.44772 14 1 13.5523 1 13V8C1 7.44772 1.44772 7 2 7H17.7872C18.3395 7 18.7872 7.44772 18.7872 8V13C18.7872 13.5523 19.2349 14 19.7872 14L24 14L8.95745 14"/>
</svg>
`


export const Car = `
<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
<path d="M21 16H22C22.5523 16 23 15.5523 23 15V12C23 11.4477 22.5523 11 22 11H2C1.44772 11 1 11.4477 1 12V15C1 15.5523 1.44772 16 2 16H3M8 16H16"/>
<path d="M5.5 19C3.5 19 3 17.3333 3 16.5C3 15.6667 3.5 14 5.5 14C7.5 14 8 15.6667 8 16.5C8 17.3333 7.5 19 5.5 19Z"/>
<path d="M18.5 19C16.5 19 16 17.3333 16 16.5C16 15.6667 16.5 14 18.5 14C20.5 14 21 15.6667 21 16.5C21 17.3333 20.5 19 18.5 19Z"/>
<path d="M4 11L7.39951 6.75061C7.77906 6.27618 8.35368 6 8.96125 6H15.0388C15.6463 6 16.2209 6.27618 16.6005 6.75061L20 11"/>
</svg>
`

