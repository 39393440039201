
<main>
    <section class="main-wrap">
        <section class="sidebar" [class.open]="pagesService.sidebar | async">
            <portal-sidebar></portal-sidebar>
        </section>
        <section class="content" [class.open]="pagesService.sidebar | async">
            <portal-header></portal-header>
            <div class="outlet">
                <router-outlet></router-outlet>
            </div>
        </section>
    </section>

    <!--<portal-footer></portal-footer>-->
</main>

