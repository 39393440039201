<main *ngIf="pagesService.pageData | async as c">
    <div class="left">
      <div class="position-right">
        <div class="intro">
          <img [src]="c.logo" [alt]="c.name">
          <h1 style="color: #fff !important;">404</h1>
          <p>Vi finner ikke siden du ser etter</p>
          <p class="small-text">©{{ now | date:'yyyy'}} {{ c.company.name }} </p>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="content">
      </div>
    </div>
  </main>
  