import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../services/pages.service';

@Component({
  selector: 'portal-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public now: Date = new Date();

  constructor(
    public pagesService: PagesService
  ) { }

  ngOnInit() {
  }

}
