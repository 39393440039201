import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../services/pages.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'portal-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  
  constructor(
    public pagesService: PagesService,
    public auth: AuthService
  ) { }

  ngOnInit() {
  }

}
