import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageData } from '../models/pages.interface';

@Injectable({
  providedIn: 'root'
})

export class PagesService {

  private _pageData: BehaviorSubject<PageData> = new BehaviorSubject(null);
  public pageData: Observable<PageData> = this._pageData.asObservable();

  private _sidebar: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public sidebar: Observable<boolean> = this._sidebar.asObservable();

  constructor() {
    this._pageData.next({
      alias: 'matrix-insurance',
      name: 'Matrix Insurance',
      logo: './assets/images/logo.svg',
      company: {
        address: 'Rådhusgata 11',
        postal: '0150 OSLO',
        phone: '23 20 79 20',
        name: 'Matrix Insurance AS',
        email: 'post@matrixinsurance.no'
      }
    })
  }


  /**
   * Toggle sidebar
   */
  toggleSidebar(){
    let sidebar = this._sidebar.getValue();
    sidebar = !sidebar;
    this._sidebar.next(sidebar);
  }


}
