import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageComponent } from './shared/components/page/page.component';
import { ByggsikkerhetGuard } from './shared/guards/byggsikkerhet.guard';
import { AuthGuard } from './shared/guards/auth.guard';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';


const routes: Routes = [
  {
    path: '',
    component: PageComponent,
    children: [      
      {
        path: '',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
        data: {path: 'Agent'},
        canActivate: [AuthGuard]
      },
      {
        path: 'customers',
        loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule),
        data: {path: 'Agent'},
        canActivate: [AuthGuard]
      },
      {
        path: 'bsg',
        loadChildren: () => import('./modules/byggsikkerhet/byggsikkerhet.module').then(m => m.ByggsikkerhetModule),
        data: {path: 'Byggsikkerhet'},
        canActivate: [AuthGuard, ByggsikkerhetGuard]
      },
      {
        path: 'skade-test',
        loadChildren: () => import('./modules/general-insurance/general-insurance.module').then(m => m.GeneralInsuranceModule),
        data: {path: 'Byggsikkerhet'},
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'login', 
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule), 
    data: { state: 'login' }
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
