import { Component, OnInit } from '@angular/core';
import { ConnectionService } from '../../services/connection.service';

@Component({
  selector: 'portal-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {

  public active: boolean = true;

  constructor(
    public connection: ConnectionService
  ) { }

  toggle(){
    this.active = !this.active;
  }

}
