<footer *ngIf="(pagesService.pageData | async) as c">
  <div class="logo-footer">
    <img [src]="c.logo" [alt]="c.name"/>
  </div>
  <div class="row footer-content margin">
    <div class="column is-4">
      <h2>Kontakt oss</h2>
      <p>{{ c.company.address }} <br/> {{ c.company.postal }} </p>
      <p>{{ c.company.phone }} </p>
      <p>{{ c.company.email }} </p>
    </div>
    <div class="column is-4">
      <h2>Om oss</h2>
      <p>Matrix har 16 ansatte med høy kompetanse og integritet. Vi jobber hver dag for å tilfredsstille alle våre
        nåværende og fremtidige kunder, og har som mål å være den beste leverandøren av
        forsikringer og garantier i Norge.</p>
    </div>
    <div class="column is-4">
      <h2>Vilkår</h2>
      <p>Du finner alle våre vilkår ved å trykke på knappen. Kontakt oss om det er spørsmål rundt disse.</p>
      <button>Se vilkår</button>
    </div>
  </div>
  <div class="copyright">
    ©{{ now | date:'yyyy'}} {{ c.company.name }} 
  </div>
</footer>
