// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCaa94-QqnR21_psSRhnoh14z5JsucGqCQ",
    authDomain: "matrix-portal.firebaseapp.com",
    databaseURL: "https://matrix-portal.firebaseio.com",
    projectId: "matrix-portal",
    storageBucket: "matrix-portal.appspot.com",
    messagingSenderId: "961718262543",
    appId: "1:961718262543:web:a1eec18defc4a69dd0fc7a",
    measurementId: "G-0JGT8GLWGW"
  },
  backbone: {
    path: 'https://251136-www.web.tornado-node.net/backbone/api/'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
