<div>
  <section class="user">
    <a class="user-wrap" [routerLink]="['']">
      <i-feather name="user"></i-feather>
    </a>
  </section>

  <nav>
    <ul *ngIf="auth.user | async as u">
      <li *ngIf="u?.resellerId" routerLinkActive="active">
        <a [routerLink]="['/customers']">
          <i-feather name="Users"></i-feather>
        </a>
      </li>
      <li *ngIf="u?.insuredId" routerLinkActive="active">
        <a [routerLink]="['/customers/' + u?.insuredId]">
          <i-feather name="User"></i-feather>
        </a>
      </li>
      <li *ngIf="u.access?.byggsikkerhet" routerLinkActive="active">
        <a [routerLink]="['/bsg']">
          <i-feather name="Test"></i-feather>
        </a>
      </li>
      <!--<li *ngIf="u.insuredId" routerLinkActive="active">
        <a [routerLink]="['/skade-test']">
          <i-feather name="Test"></i-feather>
        </a>
      </li>-->
      <li *ngIf="u.access?.garantiportalen" [routerLink]="['/gp']" routerLinkActive="active" >Garantiportalen</li>
    </ul>
  </nav>  
</div>

<section class="user">
  <div class="user-wrap logout" (click)="auth.signOut()">
    <i-feather name="log-out"></i-feather>
  </div>
</section>
