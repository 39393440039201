import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private _backupSolution: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public backupSolution: Observable<boolean> = this._backupSolution.asObservable();

  private _backupMessage: BehaviorSubject<string> = new BehaviorSubject('');
  public backupMessage: Observable<string> = this._backupMessage.asObservable();

  private _backupServer: BehaviorSubject<string> = new BehaviorSubject('');
  public backupServer: Observable<string> = this._backupServer.asObservable();

  constructor(
    private afs: AngularFirestore
  ) {
    this.afs.collection('system').doc('matrixinnsyn').valueChanges().subscribe((system: any) => {
      if(system.hasOwnProperty('backup')) {
        this._backupSolution.next(system.backup)
        if(system.backup === true || system.hasOwnProperty('backup_server_path')) this._backupServer.next(system.backup_server_path)
      } else {
        this._backupServer.next(null);
        this._backupSolution.next(false);
      };
      if(system.hasOwnProperty('backup_msg')) this._backupMessage.next(system.backup_msg);
    })
  }
}
