import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../services/pages.service';

@Component({
  selector: 'portal-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  public now: Date = new Date();
  
  constructor(
    public pagesService: PagesService
  ){ }

  ngOnInit(): void {
  }

}
