import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { UserData } from '../models/user.interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: Observable<UserData>;
  public error: BehaviorSubject<any> = new BehaviorSubject(null)

  private _token: BehaviorSubject<string> = new BehaviorSubject(null);
  public token: Observable<string> = this._token.asObservable();

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {


    this.afAuth.idToken.subscribe(token => {
      if(token){
        this._token.next(token);
        console.log(token)
      }
    })

    this.user = afAuth.authState.pipe(
      switchMap(user => {
        if(user){
          return new Promise(resolve => {
            user.refreshToken
            user.getIdTokenResult(true)
            .then(data => {
              const claims = data.claims;
              resolve({
                companyName: 'test',
                displayName: user.displayName,
                auctorId: claims.auctorId ? claims.auctorId : null,
                insuredId: claims.insuredId ? claims.insuredId : null,
                resellerId: claims.resellerId ? claims.resellerId : null,
                email: user.email,
                uid: user.uid,
                access: claims.access ? claims.access : []
              })
            })
          })
        } else return of(null);
      })
    ) as Observable<UserData>


    /**
     * Sign user out if value account is disabled
     */
    this.user.subscribe(user => {
      if(user && user.hasOwnProperty('access')){
        if(user.access.hasOwnProperty('active') && user.access.active === false){
          this.signOut();
        }
      }
    })

  }

  /**
   * SignOut
   * @param username
   * @param password
   */
  signIn(email: string, password: string){
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then(data => {
        this.updateUserData(data.user);
        this.router.navigate(['']);
      })
      .catch(error => {
        this.error.next({msg: error.message, type: error.code})
      });
  }


  /**
   * Update userdata
   * @param user
   */
  private updateUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`accounts/${user.uid}`);
    return new Promise((resolve, reject) => {
      userRef.get().pipe(map(user => {
        return user.data()
      })).toPromise()
      .then(data => {
        userRef.get().toPromise()
        this.afAuth.currentUser.then(userFB => {
          userFB.updateProfile({
            displayName: data.displayName,
            photoURL: data.photoURL
          })
        })
        userRef.set(data, { merge: true });
        resolve(true);
      })
      .catch(e => reject(e))
    })
  }


  /**
   * SignOut user
   */
  signOut(){
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/login']);
    })
  }

}
