<header >
  <div class="header-wrap">
    <div class="menu" (click)="pagesService.toggleSidebar()">
      <i-feather name="Menu"></i-feather>
    </div>
    <h2 *ngIf="auth.user | async as u">
        Matrix Portal {{'- ' + u.companyName}}
    </h2>
  </div>
</header>
