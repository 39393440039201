import { NgModule } from '@angular/core';
 
import { FeatherModule } from 'angular-feather';
import { 
  Users, Search, Heart, Clipboard, User, LogOut, Menu, Download, 
  UserCheck, Command, Home, Disc, File, Plus, AlertCircle, Package, ArrowRight 
} from 'angular-feather/icons';

import { Test, Pdf, Truck, Trailer, Car } from '../test';

const icons = {
  User, Users, Heart, LogOut, Menu, Clipboard, Package, File, Search, Plus, ArrowRight,
  Test, Pdf, Download, UserCheck, Home, Command, Disc, Truck, Trailer, Car, AlertCircle
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})
export class IconsModule { }
